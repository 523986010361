import React from "react";

import Header from "../components/Header/Header";
import Mission from "../components/Mission/Mission";
import FreightSolution from "../components/FreightSolution/FreightSolution";
import Values from "../components/Values/Values";
import Client from "../components/Clients/Clients";
import OurBackers from "../components/OurBackers/OurBackers";
import Footer from "../components/Footer/Footer";

const MainPage = () => {
  return (
    <div className="overflow-x-hidden">
      <Header />
      <Mission />
      <FreightSolution />
      <Values />
      {/* <Client /> */}
      <OurBackers />
      <Footer />
    </div>
  );
};

export default MainPage;
