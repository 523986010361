import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

import "./OurBackers.scss";
import Shape from "../../assets/Polygon_BG.png";

const Hexagon = ({ number, text, otherText }) => {
  // const countUpRef = React.useRef(null);
  return (
    <div className="hexagon">
      <img src={Shape} className="hexagon-image" />
      <div className="position-relative">
        <VisibilitySensor partialVisibility offset={{ bottom: 0 }}>
          {({ isVisible }) => (
            <div>
              {isVisible ? (
                <CountUp
                  className="numbers-font"
                  start={0}
                  end={number}
                  duration={2}
                ></CountUp>
              ) : null}
              <span className="numbers-font">{otherText}</span>
            </div>
          )}
        </VisibilitySensor>
        <p className="hexagon-numbers-text">{text}</p>
      </div>
    </div>
  );
};

const Numbers = () => {
  return (
    <div className="numbers-container">
      <p className="responsive-section-title">Our journey in numbers</p>
      <p className="section-text numbers-text-max-width">
        25% of Pakistan’s top exporters actively use BridgeLinx to move loads.
      </p>
      <div className="hexagon-group-container">
        <Hexagon number={40} text={"Loads Moved"} otherText={"k+"} />
        <Hexagon number={150} text={"Active Shippers"} otherText={"+"} />
        <Hexagon number={100} text={"Lanes Serviced"} otherText={"+"} />
        <Hexagon number={4} text={"Tons Moved / Day"} otherText={"k+"} />
      </div>
    </div>
  );
};

const Backers = () => {
  return (
    <div className="backers-container">
      <div style={{ flex: 0.3 }}>
        <p className="responsive-section-title">Our Backers</p>
        <p className="section-text backers-text-max-width">
          BridgeLinx is backed by some of the best investors from around the
          world.
        </p>
      </div>
      <div className="backers-logo-container">
        <div className="backers-logos">
          <img
            className="backers-logo"
            src="https://dg25pvdw618xy.cloudfront.net/indus_valley_capital.png"
          />
        </div>
        <div className="backers-logos">
          <img
            className="backers-logo"
            src="https://dg25pvdw618xy.cloudfront.net/VC20.png"
          />
        </div>
        <div className="backers-logos">
          <img
            className="backers-logo"
            src="https://dg25pvdw618xy.cloudfront.net/wavemaker.png"
          />
        </div>
        <div className="backers-logos">
          <img
            className="backers-logo"
            src="https://dg25pvdw618xy.cloudfront.net/quiet_logo_1.png"
          />
        </div>
        <div className="backers-logos">
          <img
            className="backers-logo"
            src="https://dg25pvdw618xy.cloudfront.net/flexport_logo.png"
          />
        </div>
        <div className="backers-logos">
          <img
            className="backers-logo"
            src="https://dg25pvdw618xy.cloudfront.net/truesight.png"
          />
        </div>
        <div className="backers-logos">
          <img
            className="backers-logo"
            src="https://dg25pvdw618xy.cloudfront.net/soma_capital.png"
          />
        </div>
        <div className="backers-logos">
          <img
            className="backers-logo"
            src="https://dg25pvdw618xy.cloudfront.net/maple_leaf.png"
          />
        </div>
      </div>
    </div>
  );
};

const OurBackers = () => {
  return (
    <div className="backers-main-container">
      <Numbers />
      <Backers />
    </div>
  );
};

export default OurBackers;
