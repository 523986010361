import React, { useRef } from "react";
import Slider from "react-slick";

import useWindowDimensions from "../../utilities/WindowDimensionHook";
import CalculateCards from "../../utilities/CalculateCards";

import "./Values.scss";
import ImageCards from "../ImageCards/ImageCards";

const Values = () => {
  const myRef = useRef(null);
  const { width, height } = useWindowDimensions();
  var settings = {
    className: "values-cards-container",
    speed: 500,
    // dots: true,
    slidesToShow: width >= 320 ? CalculateCards(width, 306, 40) : 1,
    slidesToScroll: 1,
    className: "slider-div",
    infinite: false,
  };

  const handleScrollClick = (isLeft) => {
    isLeft
      ? (myRef.current.scrollLeft -= myRef.current.scrollWidth)
      : (myRef.current.scrollLeft += myRef.current.scrollWidth);
  };

  return (
    <div className="values-container">
      <div className="values-column-left">
        <p className="responsive-section-title values-title-max-width">
          The BridgeLinx Advantage
        </p>
        <p className="section-text values-text-max-width">
          BridgeLinx is Pakistan’s cheapest, safest and most reliable freight
          solution.
        </p>
        {width >= 1025 && (
          <div style={{ display: "flex" }}>
            <button
              className="arrow-buttons"
              onClick={() => handleScrollClick(true)}
            >
              <img src="./assets/white-cheveron-left.png" />
            </button>
            <button
              className="arrow-buttons"
              onClick={() => handleScrollClick(false)}
            >
              <img src="./assets/cheveron-right.png" />
            </button>
          </div>
        )}
      </div>
      {width >= 1025 && (
        <div className="values-cards-container" ref={myRef}>
          <ImageCards
            title="Best-in-class pricing"
            text="Carrier auctions help guarantee the lowest prices for your loads, every single time."
            image="https://dg25pvdw618xy.cloudfront.net/value_1.jpg"
          />
          <ImageCards
            title="Unparalleled reliability"
            text="Load-match guarantees ensure all accepted loads are matched and lifted."
            image="https://dg25pvdw618xy.cloudfront.net/value_2.jpg"
          />
          <ImageCards
            title="Operational excellence"
            text="Timely arrivals, in-transit operational cover and loading / unloading assistance."
            image="https://dg25pvdw618xy.cloudfront.net/value_3.jpg"
          />
        </div>
      )}
      {width < 1025 && (
        <Slider {...settings}>
          <ImageCards
            title="Best-in-class pricing"
            text="Carrier auctions help guarantee the lowest prices for your loads, every single time."
            image="https://dg25pvdw618xy.cloudfront.net/value_1.jpg"
          />
          <ImageCards
            title="Unparalleled reliability"
            text="Load-match guarantees ensure all accepted loads are matched and lifted."
            image="https://dg25pvdw618xy.cloudfront.net/value_2.jpg"
          />
          <ImageCards
            title="Operational excellence"
            text="Timely arrivals, in-transit operational cover and loading / unloading assistance."
            image="https://dg25pvdw618xy.cloudfront.net/value_3.jpg"
          />
        </Slider>
      )}
    </div>
  );
};

export default Values;
