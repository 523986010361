import React from "react";
import { Link } from "react-router-dom";

import useWindowDimensions from "../../utilities/WindowDimensionHook";

import "./Footer.scss";

const Footer = () => {
  const { height, width } = useWindowDimensions();
  return (
    <div className="main-container">
      <div className="left-column">
        {width >= 1025 && (
          <Link to="/">
            <img
              width="151px"
              height="49px"
              src="./assets/bridgLinx-logo-white.png"
            />
          </Link>
        )}
        <div className="footer-links-container">
          <Link className="footer-link" to="/shippers">
            Shippers
          </Link>
          <Link className="footer-link" to="/carriers">
            Carriers
          </Link>
          <Link className="footer-link" to="/contact">
            Contact Us
          </Link>
          <Link className="footer-link" to="/careers">
            Careers
          </Link>
          {/* <Link className="footer-link" to="/">
            Privacy Policy
          </Link> */}
        </div>
        {width <= 1025 && <hr />}
        {width <= 1025 && (
          <Link style={{ margin: "0 auto 30px" }} to="/">
            <img
              width="151px"
              height="49px"
              src="./assets/bridgLinx-logo-white.png"
            />
          </Link>
        )}
        <span className={width < 1025 ? "text-align-center" : ""}>
          © 2021 Bridgelinx Private Limited. All rights reserved
        </span>
      </div>
    </div>
  );
};

export default Footer;
