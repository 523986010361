import React from "react";
import useWindowDimensions from "../../utilities/WindowDimensionHook";
import Fade from "react-reveal/Fade";

import "./CarrierTech.scss";

const HorizontalCardAnimate = ({ isDefault = true, image, title, text }) => {
  return (
    <div className="tech-container">
      {isDefault && (
        <Fade left>
          <div className="image-column">
            <img
              className="tech-image-container tech-image-right-radius"
              src={image}
            />
          </div>
        </Fade>
      )}

      {isDefault && (
        <Fade right>
          <div className="description-column">
            <p className="section-title title-padding">{title}</p>
            <p className="section-text text-padding">{text}</p>
          </div>
        </Fade>
      )}
      {!isDefault && (
        <Fade left>
          <div className="description-column">
            <p className="section-title title-padding">{title}</p>
            <p className="section-text text-padding">{text}</p>
          </div>
        </Fade>
      )}
      {!isDefault && (
        <Fade right>
          <div className="image-column">
            <img
              className="tech-image-container tech-image-left-radius"
              src={image}
            />
          </div>
        </Fade>
      )}
    </div>
  );
};

const HorizontalCard = ({ isDefault = true, image, title, text }) => {
  const { height, width } = useWindowDimensions();

  return width < 1025 ? (
    <div>
      <div className="tech-container">
        {isDefault && (
          <div className="image-column">
            <img
              className="tech-image-container tech-image-right-radius"
              src={image}
            />
          </div>
        )}
        <div className="description-column">
          <p className="section-title title-padding">{title}</p>
          <p className="section-text text-padding">{text}</p>
        </div>
        {!isDefault && (
          <div className="image-column">
            <img
              className="tech-image-container tech-image-left-radius"
              src={image}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <HorizontalCardAnimate
      isDefault={isDefault}
      image={image}
      title={title}
      text={text}
    />
  );
};

const CarrierTech = () => {
  const { width } = useWindowDimensions();
  return (
    <div>
      <div className="carrier-tech-container">
        <p className="responsive-section-title carrier-tech-title-max-width">
          Machine-driven matching and tech-enabled execution help improve
          outcomes for everyone
        </p>
      </div>
      <HorizontalCard
        title="Accessible load boards help you regain control"
        text="Use our easy-to-access, open load boards to haul the loads you want, when you want, where you want."
        image="https://dg25pvdw618xy.cloudfront.net/export_order_mockup.png"
      />
      <HorizontalCard
        isDefault={width >= 1025 ? false : true}
        title="Our nativized, intuitive product suite enables marketplace access"
        text="View, bid on and lock orders digitally, and receive tech-enabled assistance to execute any load hassle-free."
        image="https://dg25pvdw618xy.cloudfront.net/bid_mockup.png"
      />
      <HorizontalCard
        title="Work exclusively with BridgeLinx to lock in backhauls"
        text="National coverage across 100+ lanes enables timely backhauls to keep you moving without deadhead."
        image="https://dg25pvdw618xy.cloudfront.net/S1.jpg"
      />
    </div>
  );
};

export default CarrierTech;
