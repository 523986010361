import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ScrollToTop from "./utilities/ScrollToTop";

import MainPage from "./pages/MainPage";
import CarriersPage from "./pages/CarriersPage";
import ContactUsPage from "./pages/ContactUsPage";
import ShippersPage from "./pages/ShippersPage";
import HiringPage from "./pages/HiringPage";

import "./App.scss";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route exact path="/carriers" component={CarriersPage} />
        <Route exact path="/contact" component={ContactUsPage} />
        <Route exact path="/shippers" component={ShippersPage} />
        <Route exact path="/careers" component={HiringPage} />
      </Switch>
    </Router>
  );
}

export default App;
