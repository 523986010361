import React, { useRef } from "react";

import Header from "../components/Header/Header";
import Description from "../components/Description/Description";
import HiringDescription from "../components/HiringDescription/HiringDescription";
import HiringValues from "../components/HiringValues/HiringValues";
import PictureCollage from "../components/PictureCollage/PictureCollage";
import JobsOpening from "../components/JobsOpening/JobsOpening";
import Footer from "../components/Footer/Footer";

const HiringPage = () => {
  const jobRef = useRef(null);
  const executeScroll = () => jobRef.current.scrollIntoView();
  return (
    <div className="overflow-x-hidden">
      <Header />
      <HiringDescription
        title="Humility. Focus. Drive."
        text="At BridgeLinx, we’re on a mission to redefine the way in which businesses manage supply chains. If, like us, you are humbled but also driven by the enormity of our mission, you belong at BridgeLinx."
        scroll={executeScroll}
      />
      {/* <Description
        title={`Welcome to a new kind of\n freight partnership`}
        text="Bridgelinx helps you lower your shipping costs, get your freight delivered on-time, and improve your supply chain efficiency."
      /> */}
      <HiringValues />
      <PictureCollage scroll={executeScroll} />
      <JobsOpening ref={jobRef} />
      <Footer />
    </div>
  );
};

export default HiringPage;
