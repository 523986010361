import React from "react";

import "./Notepad.scss";

const Notepad = ({ image, title, text }) => {
  return (
    <div className="notepad-container">
      <div style={{ flex: 0.25 }}>
        <div className="notepad-icon-container">
          <img className="notepad-icon" src={image} />
        </div>
      </div>
      <div style={{ flex: 0.75 }}>
        <p className="notepad-title">{title}</p>
        <p className="notepad-text">{text}</p>
      </div>
    </div>
  );
};

export default Notepad;
