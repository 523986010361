import React from "react";

import "./ColorCard.scss";

const ColorCard = ({ color, title, text, icon }) => {
  return (
    <div className="color-card">
      <div className="circular-color-div" style={{ backgroundColor: color }}>
        <img src={icon} />
      </div>
      <p className="color-card-title">{title}</p>
      <p className="color-card-text">{text}</p>
    </div>
  );
};

export default ColorCard;
