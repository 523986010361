import React from "react";
import useWindowDimensions from "../utilities/WindowDimensionHook";

import Header from "../components/Header/Header";
import SlantedBg from "../components/SlantedBg/SlantedBg";
import Description from "../components/Description/Description";
import CarrierTech from "../components/CarrierTech/CarrierTech";
import ContactUs from "../components/ContactUs/ContactUs";
import Footer from "../components/Footer/Footer";

import "../App.scss";

const CarriersPage = () => {
  const { width, height } = useWindowDimensions();

  return (
    <div className="position-relative">
      <SlantedBg
        heightBg={width >= 1025 ? "1300px" : "930px"}
        color="#1c75bc"
      />
      <SlantedBg
        heightBg={width >= 1025 ? "1295px" : "925px"}
        color="#edf7ff"
      />
      <Header />
      <Description
        isCarrier={true}
        title="Together, we can move mountains"
        text={`Join thousands of truckers, owner-operators, private fleet owners and logistics companies that\n have chosen to haul with BridgeLinx.`}
      />
      <CarrierTech />
      <ContactUs
        text={
          "Thousands of carriers use BridgeLinx’s marketplace to increase take-home earnings by up to 4x. Join our carrier-first team today."
        }
        isShipper={false}
      />
      <Footer />
    </div>
  );
};

export default CarriersPage;
