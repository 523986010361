import React, { forwardRef, useEffect } from "react";
// import axios from "axios";

import "./JobsOpening.scss";

const JobCategory = ({ title, jobs }) => {
  return (
    <div className="job-category-container">
      <p style={{ marginBottom: "12px" }}>Product Teams</p>
      {jobs &&
        jobs.length > 0 &&
        jobs.map((job) => {
          return (
            <Jobs
              key={job.name}
              title={job.name}
              location={job.location}
              id={job._id}
              url={job.url}
            />
          );
        })}
    </div>
  );
};

const Jobs = ({ title, location, id, url }) => {
  // const url = "https://bridgelinx-technologies.breezy.hr/";

  return (
    <div className="jobs">
      <span className="job-title-text">{title}</span>
      <div className="job-details">
        <div className="job-location-container">
          <img className="job-location-icon" src="./assets/location.png" />
          <span className="job-location-text">{location}</span>
        </div>
        <button
          className="job-apply-button"
          onClick={() => {
            window.open(`${url}`, "_blank");
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

const JobsOpening = forwardRef((props, ref) => {
  // const [positions, setPositions] = useState(null);

  // useEffect(() => {
  //   axios
  //     .get("https://bridgelinx-technologies.breezy.hr/json?verbose=true")
  //     .then((res) => {
  //       setPositions(res.data);
  //     })
  //     .catch((error) => {
  //       console.log("Error: ", error);
  //     });
  // }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://bridgelinx.bamboohr.com/js/embed.js";
    script.type = "text/javascript"
    document.body.appendChild(script);
    // setPositions([
    //   { name: "Farooq", location: "Lahore, Pakistan", _id: "124", url: "heheheheh" },
    //   { name: "Farooq", location: "Lahore, Pakistan", _id: "124", url: "heheheheh" },
    // ])
  }, []);

  return (
    <div className="jobs-main-container" ref={ref}>
      <p className="responsive-section-title padding-job-text">Openings</p>
      {/* <p className="section-text padding-job-text">
        We’re always on the lookout for enthusiastic and driven people to join
        the Bridgelinx family. If you do not see a position that is a match,
        send in your resume for future openings at hr@bridgelinx.pk
      </p> */}
      <div className="jobs-container">
        {/* {positions && <JobCategory title="Product" jobs={positions} />} */}
        {/* <JobCategory title="Product" /> */}
          <div id="BambooHR" data-domain="bridgelinx.bamboohr.com" data-version="1.0.0" data-departmentId=""></div>
          <div className="fixed_container" />
      </div>


      {/* <div id="bzOpeningsContainer"></div>
      <script src="https://bridgelinx-technologies.breezy.hr/embed/js?inline=true&group_by=dept"></script> 
      */}
      {/* <iframe
        id="BrzyHr_app"
        src={
          "https://bridgelinx-technologies.breezy.hr/embed/js?bzsrc=jswidget&include_filters=true&link_external=true&no_pos_msg=true"
        }
      /> */}
    </div >
  );
});

export default JobsOpening;
