import React from "react";

import "./ImageCards.scss";

const ImageCards = ({ title, text, image }) => {
  console.log("Image ", image);
  return (
    <div className="image-card-container">
      <img className="card-image" src={image} />
      <div className="image-card-text-container">
        <p className="image-card-title">{title}</p>
        <p className="image-card-text">{text}</p>
      </div>
    </div>
  );
};

export default ImageCards;
