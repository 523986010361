import React from "react";

import "./FreightSolution.scss";
import useWindowDimensions from "../../utilities/WindowDimensionHook";
import Notepad from "../Notepad/Notepad";

const FreightSolution = () => {
  const { height, width } = useWindowDimensions();
  return (
    <div className="solution-main-container">
      <div className="solution-container">
        <p className="responsive-section-title freight-title-max-width">
          BridgeLinx is setting the bar for coverage and service in road freight
        </p>
        <div className="solution-card-container">
          <Notepad
            image="./assets/path.png"
            title="End-to-end load execution"
            text="From port operations to documentation and settlement, our solutions provide tailored, end-to-end coverage."
          />
          <Notepad
            image="./assets/devices-teal.png"
            title="Hassle-free shipping"
            text="Our mobile and web solutions help you book, track and trouble-shoot loads without having to make a single phone call."
          />
        </div>
        <div className="solution-card-container2">
          <Notepad
            image="./assets/location_yellow.png"
            title="National coverage, local expertise"
            text="We connect local supply and operational networks to provide coverage across 100+ lanes in Pakistan."
          />
          <Notepad
            image="./assets/user_voice.png"
            title="Personalized, round-the-clock service"
            text="Dedicated account managers and 24/7 support to help ensure that you never fret about freight."
          />
        </div>
      </div>
      {width >= 1025 && (
        <div className="solution-image-container">
          <img
            className="solution-image"
            src="https://dg25pvdw618xy.cloudfront.net/second_section_image.png"
          />
        </div>
      )}
    </div>
  );
};

export default FreightSolution;
