import React from "react";
import { Link } from "react-router-dom";

import "./ContactUs.scss";

const ContactButton = () => {
  return (
    <Link to="/contact" className="contact-button-link">
      <button className="contact-us-button">
        Contact Us{" "}
        <img
          style={{ margin: "0 0 0 16px" }}
          src="./assets/arrow-right-black.png"
        />
      </button>
    </Link>
  );
};

const ContactUs = ({ text, isShipper = true }) => {
  return (
    <div
      className={isShipper ? "contact-us-container" : "contact-us-container2"}
    >
      <p className="contact-us-text">{text}</p>
      <ContactButton />
    </div>
  );
};

export default ContactUs;
