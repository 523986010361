import React, { useEffect, useState } from "react";

import useWindowDimensions from "../../utilities/WindowDimensionHook";
import Notepad from "../Notepad/Notepad";
import "./ShippersTech.scss";

const techItems = [
  {
    id: 1,
    text: "Segment-specific order intake within a few taps using our mobile app",
    icon: "./assets/devices.png",
    image: "https://dg25pvdw618xy.cloudfront.net/1S.png",
  },
  {
    id: 2,
    text: "Live, on-platform visibility on vehicle and driver assignment",
    icon: "./assets/path.png",
    image: "https://dg25pvdw618xy.cloudfront.net/2S.png",
  },
  {
    id: 3,
    text: "Live tracking of shipments at every stage of load execution",
    icon: "./assets/location_blue.png",
    image: "https://dg25pvdw618xy.cloudfront.net/3S.png",
  },
  {
    id: 4,
    text: "On-platform access to documentation, order histories and load analytics",
    icon: "./assets/calendar_blue.png",
    image: "https://dg25pvdw618xy.cloudfront.net/4S.png",
  },
];

const TechCardText = ({ text }) => {
  return (
    <p
      style={{
        textAlign: "center",
        fontFamily: "InterBold",
        fontSize: "14px",
        marginBottom: "32px",
      }}
    >
      {text}
    </p>
  );
};

const TechCard = ({ selected, techItem, onChange }) => {
  const { width } = useWindowDimensions();

  return (
    <>
      <div
        className="shippers-tech-card"
        onClick={() => {
          onChange(techItem.id);
        }}
        style={{
          border: selected ? "3px solid #1C75BC" : "none",
          backgroundColor: selected ? "white" : "transparent",
          boxShadow: selected ? "0px 12px 17px 0px rgba(0,0,0, 0.12)" : "none",
        }}
      >
        <div style={{ padding: width >= 1025 ? "0 25px" : "0 9px" }}>
          <img width="24px" height="24px" src={techItem.icon} />
        </div>
        {width >= 1025 && (
          <p style={{ margin: "0 32px 0 0", lineHeight: "2" }}>
            {techItem.text}
          </p>
        )}
      </div>
    </>
  );
};

const ShippersTech = () => {
  const { width } = useWindowDimensions();
  const [selectedId, setSelectedId] = useState(1);
  const [selectedItem, setSelectedItem] = useState(techItems[0]);

  useEffect(() => {
    setSelectedItem(techItems[selectedId - 1]);
  }, [selectedId]);

  const onChange = (id) => {
    setSelectedId(id);
  };

  return (
    <>
      <div>
        <div className="shippers-mission-container">
          {width >= 1025 && (
            <div className="shippers-mission-description">
              <p className="responsive-section-title">
                Awe-inspiring tech, stellar service
              </p>
              <p className="section-text shippers-tech-text">
                We believe in delivering amazing experiences to our clients by
                combining ever-improving technology with personalized,
                industry-beating service.
              </p>
            </div>
          )}
          <div className="devices-image-container">
            <img
              src="https://dg25pvdw618xy.cloudfront.net/product_mockup.png"
              className="devices-image"
            />
          </div>
          {width < 1025 && (
            <div className="shippers-mission-description">
              <p className="responsive-section-title">
                Ship your goods with our extensive product suite{" "}
              </p>
              <p className="section-text shippers-tech-text">
                From executing on-demand retail deliveries to managing complex
                and integrate supply chains, our customized solutions can help
                you reclaim time and money.
              </p>
            </div>
          )}
        </div>
        <div className="shippers-notepad-container">
          <Notepad
            image="./assets/credit_card.png"
            title="Best-in-class pricing"
            text="Our pricing algorithms help eliminate pricing risk, whether you transact in spot or contractual markets."
          />
          <Notepad
            image="./assets/repeat.png"
            title="Guaranteed matching"
            text="A load accepted by BridgeLinx is a load matched. No exceptions."
          />
          <Notepad
            image="./assets/id_card.png"
            title="White glove service"
            text="Dedicated account managers ensure your shipments are handled with the attention  and care they deserve."
          />
        </div>
      </div>
      <div className="shippers-tech-container">
        <div className="shippers-tech-column">
          <p
            style={{ marginBottom: "24px" }}
            className="responsive-section-title"
          >
            Technology that works
          </p>
          <div className="shippers-tech-cards-container">
            {techItems.map((item) => {
              return (
                <TechCard
                  key={item.id}
                  selected={item.id === selectedId}
                  techItem={item}
                  onChange={onChange}
                />
              );
            })}
          </div>
        </div>
        <div className="shippers-tech-column">
          {width < 1025 && selectedItem !== undefined && (
            <TechCardText text={selectedItem.text} />
          )}
          <img className="shippers-tech-image" src={selectedItem.image} />
        </div>
      </div>
    </>
  );
};

export default ShippersTech;
