import React from "react";

import Notepad from "../Notepad/Notepad";
import "./HiringValues.scss";

const HiringValues = () => {
  return (
    <div className="hiring-values-container">
      <p className="hiring-values-section-title">
        We are defined by our values
      </p>
      <div className="hiring-values-card-container">
        <Notepad
          image="./assets/trending_up.png"
          title="Speed as a Habit"
          text="“Speed is the ultimate weapon in business… Startups are fast. Big companies are slow.” David Girouard, CEO - Upstart."
        />
        <Notepad
          image="./assets/black_lives_matter.png"
          title="Ownership"
          text="Knowing where the buck stops. Owning tasks, actionables and initiatives. Leading from the front."
        />
        <Notepad
          image="./assets/check_all_big.png"
          title="Service Excellence"
          text="We value our customer-centric culture - over-delivering, empathizing, honouring commitments and driving up switching costs."
        />
      </div>
    </div>
  );
};

export default HiringValues;
