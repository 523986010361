import React from "react";
import useWindowDimensions from "../utilities/WindowDimensionHook";

import Header from "../components/Header/Header";
import SlantedBg from "../components/SlantedBg/SlantedBg";
import Description from "../components/Description/Description";
import ShippersTech from "../components/ShippersTech/ShippersTech";
import ContactUs from "../components/ContactUs/ContactUs";
import Footer from "../components/Footer/Footer";

const ShippersPage = () => {
  const { width, height } = useWindowDimensions();
  return (
    <div className="position-relative">
      <SlantedBg
        heightBg={
          width >= 1025 ? (width > 1440 ? "1450px" : "1150px") : "1100px"
        }
        color="#1c75bc"
      />
      <SlantedBg
        heightBg={
          width >= 1025 ? (width > 1440 ? "1445px" : "1145px") : "1095px"
        }
        color="#edf7ff"
      />

      <Header />
      <Description
        title={`Shipping is cumbersome and expensive. It doesn’t have to be`}
        text={`Our feature-packed product solutions, backed by best-in-industry customer service, make shipping convenient, cost-effective and - dare we say - enjoyable.`}
      />
      <ShippersTech />
      <ContactUs
        text={
          "Eliminate the drudgery of endless telephone calls, missed loads and poor service. Join Pakistan’s largest shippers on BridgeLinx’s always-on marketplace."
        }
      />
      <Footer />
    </div>
  );
};

export default ShippersPage;
