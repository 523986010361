import React from "react";

import "./HiringDescription.scss";

const HiringDescription = ({ title, text, scroll }) => {
  return (
    <div className="hiring-desc-container">
      <div className="hiring-desc-column-left">
        <p className="page-title hiring-title-max-width">{title}</p>
        <p className="page-text hiring-text-max-width">{text}</p>
        <button
          className="openings-button hiring-button-margin-top"
          onClick={() => scroll()}
        >
          See Job Openings{" "}
          <img style={{ margin: "0 0 0 16px" }} src="./assets/arrow-down.png" />
        </button>
      </div>
      <div>
        <img
          src="https://dg25pvdw618xy.cloudfront.net/DSC03727.jpg"
          className="hiring-desc-image"
          alt="Bridgelinx Team"
        ></img>
      </div>
    </div>
  );
};

export default HiringDescription;
