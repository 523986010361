import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import axios from "axios";

import useWindowDimensions from "../../utilities/WindowDimensionHook";
import "./ContactUsForm.scss";

const ContactFormInfo = ({ google }) => {
  const { width } = useWindowDimensions();

  return (
    <div className="contact-column-right">
      <div className="form-container contact-info-margin">
        <div className="flexible-row">
          <img className="contact-icons" src="./assets/location.png" />
          <p>
            BridgeLinx HQ, Major Mustafa <br />
            Sabir Shaheed Rd, Cantt, Lahore
          </p>
        </div>
        {/* <div className="flexible-row">
          <img src="./assets/phone.png" />
          <p>+42 000-1236-4568</p>
        </div> */}
        <div className="flexible-row">
          <img className="contact-icons" src="./assets/email.png" />
          <p>info@bridgelinxpk.com</p>
        </div>
        <div className="flexible-row flexible-row-left-padding ">
          <div
            onClick={() => {
              window.open(
                "https://www.linkedin.com/company/bridgelinx/mycompany/",
                "_blank"
              );
            }}
          >
            <img className="contact-icons" src="./assets/linkedin.png" />
          </div>
          <div
            onClick={() => {
              window.open("https://twitter.com/BridgeLinxTech", "_blank");
            }}
          >
            <img
              style={{ marginLeft: "27px" }}
              className="contact-icons"
              src="./assets/twitter.png"
            />
          </div>
        </div>

        <div
          style={{ width: width < 1025 ? "100%" : "370px", height: "280px" }}
        >
          <Map
            google={google}
            zoom={14}
            style={{ width: width < 1025 ? "100%" : "370px", height: "280px" }}
            containerStyle={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
            initialCenter={{ lat: 31.504194, lng: 74.413552 }}
            scrollwheel={false}
            draggable={false}
            keyboardShortcuts={false}
            zoomControl={false}
            mapTypeControl={false}
            streetViewControl={false}
            fullscreenControl={false}
          >
            <Marker name={"Bridgelinx"} />
          </Map>
        </div>
      </div>
    </div>
  );
};

const ContactUsForm = ({ google }) => {
  const { width } = useWindowDimensions();
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleFormSubmit = () => {
    const isValid =
      name !== "" &&
      emailAddress !== "" &&
      phoneNumber !== "" &&
      message !== "";
    if (isValid) {
      console.log("Valid form");
      axios
        .post(
          "https://asia-south1-bridgelinx-client.cloudfunctions.net/sendGridContactUsEmail",
          { name, phoneNumber, emailAddress, message }
        )
        .then((res) => {
          console.log("Response: ", res);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    } else {
      console.log("Not Valid");
    }
  };

  return (
    <>
      <div className="contact-form">
        <div className="contact-column-left">
          <div className="form-container">
            <p className={"form-title"}>Leave us a message</p>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="contactForm.ControlInput1"
              >
                <Form.Label className="contact-input-label">Name</Form.Label>
                <Form.Control
                  className="contact-input"
                  type="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="contactForm.ControlInput2"
              >
                <Form.Label className="contact-input-label">
                  Phone Number
                </Form.Label>
                <Form.Control
                  className="contact-input"
                  type="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="contactForm.ControlInput2"
              >
                <Form.Label className="contact-input-label">
                  Email Address
                </Form.Label>
                <Form.Control
                  className="contact-input"
                  type="email"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="contactForm.ControlTextarea1"
              >
                <Form.Label className="contact-input-label">
                  Your Message
                </Form.Label>
                <Form.Control
                  className="contact-input"
                  as="textarea"
                  rows={2}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>
              <button
                className="contact-form-button"
                // type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleFormSubmit();
                }}
              >
                Send
              </button>
            </Form>
          </div>
        </div>
        {width >= 1025 && <ContactFormInfo google={google} />}
      </div>
      {width <= 1025 && <ContactFormInfo google={google} />}
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCQvfbYZc9HKiJj6CLnA2PTn2j-WG7Ryn8",
})(ContactUsForm);
