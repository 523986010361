import React from "react";

import "./SlantedBg.scss";

const SlantedBg = ({ heightBg, color }) => {
  return (
    <div
      style={{
        height: heightBg,
        background: `linear-gradient(
        4deg,
        transparent 50%,
        ${color} calc(50% + 2px)
      )`,
      }}
      className="slanted-background"
    ></div>
  );
};

export default SlantedBg;
