import React from "react";
import Slider from "react-slick";

import useWindowDimensions from "../../utilities/WindowDimensionHook";
import CalculateCards from "../../utilities/CalculateCards";

import "./PictureCollage.scss";

const PictureCollage = ({ scroll }) => {
  const { width } = useWindowDimensions();
  var settings = {
    dots: false,
    slidesToShow:
      CalculateCards(width, 280, 50) > 4 ? 4 : CalculateCards(width, 280, 50),
    slidesToScroll: 1,
    infinite: false,
  };
  return (
    <div className="pictures-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          textAlign: "center",
        }}
      >
        <p className="responsive-section-title">We're Hiring</p>
        {width >= 1025 && (
          <button className="openings-button" onClick={() => scroll()}>
            See Job Openings{" "}
            <img
              style={{ margin: "0 0 0 16px" }}
              src="./assets/arrow-down.png"
            />
          </button>
        )}
      </div>

      <p className="section-text picutre-collage-text">
        {`Join our mission-driven team of ambitious entrepreneurs and exceptional\n operators by selecting an opening below. Or, email us at\n careers@bridgelinxpk.com with your unique pitch!`}
      </p>

      {width < 1025 && (
        <button
          style={{ margin: "32px 0 40px" }}
          className="openings-button"
          onClick={() => scroll()}
        >
          See Job Openings{" "}
          <img style={{ margin: "0 0 0 16px" }} src="./assets/arrow-down.png" />
        </button>
      )}
      {width >= 1025 && (
        <div className="picture-collage-container">
          <div style={{ minWidth: "463px", marginRight: "32px" }}>
            <img
              className="image-size"
              src="https://dg25pvdw618xy.cloudfront.net/DSC03637.jpg"
              alt="Culture at Bridgelinx"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "783px",
                height: "415px",
              }}
            >
              <img
                className="image-size"
                src="https://dg25pvdw618xy.cloudfront.net/DSC03624.jpg"
                alt="Bridgelinx product team"
                style={{ objectPosition: "bottom" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "375px",
                  height: "300px",
                }}
              >
                <img
                  className="image-size"
                  src="https://dg25pvdw618xy.cloudfront.net/DSC03670.jpg"
                  alt=""
                />
              </div>
              <div
                style={{
                  width: "375px",
                  height: "300px",
                }}
              >
                <img
                  className="image-size"
                  src="https://dg25pvdw618xy.cloudfront.net/DSC03661.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {width < 1025 && (
        <Slider {...settings}>
          <div className="slider-pic-container">
            <img
              className="slider-image"
              src="https://dg25pvdw618xy.cloudfront.net/DSC03637.jpg"
            />
          </div>
          <div className="slider-pic-container">
            <img
              className="slider-image"
              src="https://dg25pvdw618xy.cloudfront.net/DSC03624.jpg"
            />
          </div>
          <div className="slider-pic-container">
            <img
              className="slider-image"
              src="https://dg25pvdw618xy.cloudfront.net/DSC03670.jpg"
            />
          </div>
          <div className="slider-pic-container">
            <img
              className="slider-image"
              src="https://dg25pvdw618xy.cloudfront.net/DSC03661.jpg"
            />
          </div>
        </Slider>
      )}
    </div>
  );
};

export default PictureCollage;
