import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import useWindowDimensions from "../../utilities/WindowDimensionHook";
import { ReactComponent as Logo } from "../../assets/Bridgelinx_logo.svg";

import "./Header.scss";

const Header = () => {
  const { width } = useWindowDimensions();
  const [isExpand, setExpand] = useState(false);

  useEffect(() => {
    if (width >= 1025) {
      setExpand(false);
    }
  }, [width]);

  useEffect(() => {
    if (isExpand) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isExpand]);

  const toggleExpand = () => {
    setExpand(!isExpand);
  };

  return (
    <div
      className="navbar-main-container"
      style={{
        backgroundColor: width < 1025 ? "white" : "transparent",
        height: isExpand ? "100vh" : "88px",
        boxShadow:
          width < 1025 && !isExpand ? "0 1px 0 rbga(0,0,0,0.12)" : "none",
      }}
    >
      <div className="navbar-container">
        <NavLink to="/" className="navbar-logo-container">
          <Logo className="navbar-logo" />
        </NavLink>
        {width >= 1025 && (
          <div className="navlinks-container">
            <NavLink
              activeClassName="nav-font-active"
              to="/shippers"
              className="nav-fonts"
            >
              Shippers
            </NavLink>
            <NavLink
              activeClassName="nav-font-active"
              to="/carriers"
              className="nav-fonts"
            >
              Carriers
            </NavLink>
            <NavLink
              activeClassName="nav-font-active"
              to="/contact"
              className="nav-fonts"
            >
              Contact Us
            </NavLink>
            <NavLink
              activeClassName="nav-font-active"
              to="/careers"
              className="nav-fonts"
            >
              Careers
            </NavLink>
          </div>
        )}
        {width < 1025 && (
          <div
            className="burger-icon-container"
            onClick={() => {
              toggleExpand();
            }}
          >
            <img
              src={isExpand ? "./assets/close.png" : "./assets/menu.png"}
              width="100%"
              height="100%"
            />
          </div>
        )}
      </div>

      {width < 1025 && isExpand && (
        <div className="navbar-expand-containerss">
          <NavLink
            activeClassName="nav-font-active"
            to="/shippers"
            className="nav-fonts nav-fonts-container"
          >
            <p>Shippers</p>
            <img src="./assets/chevron-right-black.png" />
          </NavLink>
          <NavLink
            activeClassName="nav-font-active"
            to="/carriers"
            className="nav-fonts nav-fonts-container"
          >
            <p>Carriers</p>
            <img src="./assets/chevron-right-black.png" />
          </NavLink>
          <NavLink
            activeClassName="nav-font-active"
            to="/contact"
            className="nav-fonts nav-fonts-container"
          >
            <p>Contact Us</p>
            <img src="./assets/chevron-right-black.png" />
          </NavLink>
          <NavLink
            activeClassName="nav-font-active"
            to="/careers"
            className="nav-fonts nav-fonts-container"
          >
            <p>Careers</p>
            <img src="./assets/chevron-right-black.png" />
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default Header;
