import React from "react";
import Slider from "react-slick";

import useWindowDimensions from "../../utilities/WindowDimensionHook";
import CalculateCards from "../../utilities/CalculateCards";

import ColorCard from "../ColorCard/ColorCard";
import "./Description.scss";

const Description = ({ title, text, isCarrier = false }) => {
  const { width, height } = useWindowDimensions();
  var settings = {
    // dots: true,
    speed: 500,
    slidesToShow:
      CalculateCards(width, 296, 40) > 3 ? 3 : CalculateCards(width, 296, 40),
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
  };

  return (
    <div
      className="description-container"
      style={{ padding: isCarrier && width < 1025 && "45px 0 45px 5%" }}
    >
      <p className="page-title page-title-margin-bottom">{title}</p>
      <p className="page-text page-text-margin-bottom">{text}</p>
      {isCarrier && width >= 1025 && (
        <div className="carrier-card-container">
          <ColorCard
            color="#4CAF50"
            title="Guaranteed loads..."
            text="Our vast shipper base helps us source as many loads as you can haul."
            icon="./assets/check_bold.png"
          />
          <ColorCard
            color="#EF5DA8"
            title="… on the lanes you want..."
            text="You like your lanes, and we’re here to make sure you can stay on those lanes."
            icon="./assets/expand.png"
          />
          <ColorCard
            color="#9C27B0"
            title="… with payment guarantees"
            text="BridgeLinx guarantees payment upon load execution, so you can keep hauling."
            icon="./assets/credit_card_white.png"
          />
        </div>
      )}
      {isCarrier && width < 1025 && (
        <Slider {...settings}>
          <ColorCard
            color="#4CAF50"
            title="Guaranteed loads..."
            text="Our vast shipper base helps us source as many loads as you can haul."
            icon="./assets/check_bold.png"
          />
          <ColorCard
            color="#EF5DA8"
            title="… on the lanes you want..."
            text="You like your lanes, and we’re here to make sure you can stay on those lanes."
            icon="./assets/expand.png"
          />
          <ColorCard
            color="#9C27B0"
            title="… with payment guarantees"
            text="BridgeLinx guarantees payment upon load execution, so you can keep hauling."
            icon="./assets/credit_card_white.png"
          />
        </Slider>
      )}
    </div>
  );
};

export default Description;
