import React from "react";
import useWindowDimensions from "../utilities/WindowDimensionHook";

import Header from "../components/Header/Header";
import SlantedBg from "../components/SlantedBg/SlantedBg";
import Description from "../components/Description/Description";
import ContactUsForm from "../components/ContactUsForm/ContactUsForm";
import Footer from "../components/Footer/Footer";

import "../App.scss";

const ContactUsPage = () => {
  const { width, height } = useWindowDimensions();

  return (
    <div className="position-relative">
      {/* <SlantedBg heightBg="1400px" color="#1c75bc" />
      <SlantedBg heightBg="1395px" color="#edf7ff" /> */}

      <SlantedBg
        heightBg={width >= 1025 ? "1200px" : "930px"}
        color="#1c75bc"
      />
      <SlantedBg
        heightBg={width >= 1025 ? "1195px" : "925px"}
        color="#edf7ff"
      />
      <Header />
      <Description
        title="Contact Us"
        text={`Whether you are a shipper looking to test-drive our marketplace, or a carrier seeking\n a reliable brokerage partner, we’re here to help - 24/7/365.`}
      />
      <ContactUsForm />
      <Footer />
    </div>
  );
};

export default ContactUsPage;
