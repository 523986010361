import React from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";

import useWindowDimensions from "../../utilities/WindowDimensionHook";

import "./Mission.scss";

const Mission = () => {
  const { width } = useWindowDimensions();
  return (
    <div className="mission-container">
      <div className="mission-left-column">
        <p className="mission-title page-title-max-width">
          Pakistan’s Leading Freight Marketplace
        </p>
        <p className="mission-text page-text-max-width">
          BridgeLinx helps Pakistan’s largest shippers move thousands of loads
          each week on the country’s fastest-growing freight marketplace.
        </p>
        <div
          style={{
            display: "flex",
            margin: width >= 1024 ? "48px 0 0" : "24px 0 10px",
          }}
        >
          <Link to="/shippers">
            <button className="button-arrow">
              Shippers{" "}
              <img
                style={{ margin: "0 0 0 16px" }}
                src="./assets/arrow-right.png"
              />
            </button>
          </Link>
          <Link to="/carriers">
            <button className="button-arrow">
              Carriers{" "}
              <img
                style={{ margin: "0 0 0 16px" }}
                src="./assets/arrow-right.png"
              />
            </button>
          </Link>
        </div>
      </div>
      <div className="mission-right-column">
        <div
          style={{
            width: "100%",
            position: "relative",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <img
            src="https://dg25pvdw618xy.cloudfront.net/main_header_picture.jpg"
            className="mission-image1"
          />
        </div>
      </div>
    </div>
  );
};

export default Mission;
